import React from 'react';
import css from './SectionStayListing.module.css';
import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';
import { FiChevronLeft } from 'react-icons/fi';
import { BiChevronRight } from 'react-icons/bi';
import { IconSpinner, ListingCard } from '../../../components';
const SectionStayListing = props => {
  const { listings = [], listingsLoading, listingsError, heading, search } = props;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');
  if (listingsLoading) {
    return (
      <div>
        <h1 className={css.heading}>{heading}</h1>
        <div className={css.root}>
          <div className={css.container}>
            <IconSpinner />
          </div>
        </div>
      </div>
    );
  }

  if (listingsError) {
    return (
      <div>
        <h1 className={css.heading}>{heading}</h1>
        <div className={css.root}>
          <div className={css.container}>
            <p className={css.error}>
              {listingsError.message
                ? listingsError.message
                : 'Something went wrong. Please refresh the page.'}
            </p>
          </div>
        </div>
      </div>
    );
  }
  if (listings && listings?.length === 0) {
    return null;
  }
  return (
    <div>
      <h1 className={css.heading}>{heading}</h1>
      <div className={css.root}>
        <Carousel
          additionalTransfrom={0}
          arrows={true}
          autoPlaySpeed={3000}
          centerMode={false}
          className=""
          containerClass="container"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktopSuper: {
              breakpoint: {
                max: 3000,
                min: 1340,
              },
              items: 3.8,
              partialVisibilityGutter: 40,
            },
            desktop: {
              breakpoint: {
                max: 1340,
                min: 1100,
              },
              items: 3.1,
              partialVisibilityGutter: 40,
            },

            tabletMedium: {
              breakpoint: {
                max: 1100,
                min: 900,
              },
              items: 2.2,
              partialVisibilityGutter: 0,
            },
            tabletMedium: {
              breakpoint: {
                max: 900,
                min: 800,
              },
              items: 2,
              partialVisibilityGutter: 0,
            },
            tablet: {
              breakpoint: {
                max: 800,
                min: 650,
              },
              items: 1.8,
              partialVisibilityGutter: 0,
            },
            mobile: {
              breakpoint: {
                max: 600,
                min: 500,
              },
              items: 1.3,
              partialVisibilityGutter: 0,
            },
            sMobile: {
              breakpoint: {
                max: 500,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 0,
            },
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {listings.map(l => (
            <ListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes}
              isLandingPage={true}
            />
          ))}
          {/* 
    
          {listings.map(l => (
            <ListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes}
              isLandingPage={true}
            />
          ))}
        </div>
      </div> */}
        </Carousel>
      </div>
    </div>
  );
};

export default SectionStayListing;
